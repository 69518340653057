.cookiebanner {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #0b0c0c;
    color: #fff;
    font-size: 16px;
    padding: 12px 18px 36px;
    line-height: 26px;
    z-index: 100;
    display: block;
    text-align: center;
    
}
.cookiebannerbutton {
    margin-top: 12px;
    margin-left: 12px;
    box-shadow: 0 2px 0 0 #003d2f;
    border-radius: 4px;
    padding: 12px 24px;
    font-size: 19px;
    width: 100%;
    box-sizing: inherit;
    max-width: 200px;
}