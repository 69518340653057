@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loader {
  margin: auto;
  border: 10px solid rgba(0, 0, 0, 0.1);
  border-left-color: #1d70b8;
  border-radius: 50%;
  width: 15vw;
  height: 15vw;
  animation: loader-spin 1.2s linear infinite;
}
